import $ from "jquery";

$('.filter a').on('click',function(e) {
  e.preventDefault()
  $('.date-filter a').removeClass('active').closest('.date-filter').find('.dropdown').removeClass('active');
  $('.filter a').not($(this)).closest('.filter').removeClass('active').find('.dropdown').removeClass('active');
  $(this).closest('.filter').toggleClass('active').find('.dropdown').toggleClass('active');
  $(this).closest('#desktop-filter').find('.filter-bg').toggleClass('active');
})

$('.date-filter a').on('click',function(e) {
  e.preventDefault()
  $('.filter').removeClass('active').find('.dropdown').removeClass('active');
  $(this).toggleClass('active').closest('.date-filter').find('.dropdown').toggleClass('active');
  $(this).closest('.date-filter').find('.filter-bg').toggleClass('active');
})

$('.date-filter .dropdown .checkbox').on('click',function(e) {
  e.preventDefault()
  $('.date-filter input').prop('checked',false);
  $(this).find('input').prop('checked',true);
  getPosts()
})

$('.collapse .checkbox').on('click',function(e) {
  e.preventDefault()

  if ($(this).hasClass('date')) {
    $('.accordion-body').find('input').prop('checked',false)
    $(this).find('input').prop('checked',true)
  } else {
    if ($(this).find('input').prop('checked')) {
      $(this).find('input').prop('checked',false);
    } else {
      $(this).find('input').prop('checked',true);
    }
  }
})

$('.filter-bg').on('click',function() {
  $(this).removeClass('active')
  $('.filter, .date-filter').find('.dropdown').removeClass('active');
  $(this).remove('active');
})

$('#filter-buttons').on('click', 'a' ,function(e) {
  e.preventDefault()
  $('input[value="'+ $(this).attr('data-name') +'"]').prop('checked',false);

  $(this).remove()
  getPosts()

  if ($('#filter-list #filter-buttons a').length == 0) {
    $('#filter-list').removeClass('active')
  } else {
    $('#filter-list').addClass('active')
  }
})

$('#clear-all').on('click',function(e) {
  e.preventDefault()
  $('#filter-list').removeClass('active')
  $('#filter-list a:not(#clear-all)').remove()
  $('.filters input').prop('checked',false);
  $('#mobile-filter-menu').removeClass('active')
  $('#mobile-filter-menu input').prop('checked',false);
  getPosts()
  getPosts('mobile')
})

$('#clear-all-2').on('click',function(e) {
  e.preventDefault()
  $('#mobile-filter-menu').removeClass('active')
  $('#mobile-filter-menu input').prop('checked',false);
  getPosts()
})

$('#mobile-filter').on('click',function() {
  $('#mobile-filter-menu').addClass('active')
})

$('#filter-close, #show-results').on('click',function() {
  $('#mobile-filter-menu').removeClass('active')
})

$('#work-filter2 .checkbox-all').on('click',function(){
  if ($(this).closest('label').find('input').is(':checked')) {
    console.log('123123')
    $(this).closest('.accordion-body').find('input:checked').click()
  } else {
    console.log('123123test')
    $(this).closest('.accordion-body').find('input:not(:checked)').click()
  }
});

$('#work-filter2 .checkbox:not(.checkbox-all)').on('click',function(){
  if ($(this).closest('.filters').length == 0 && !$(this).hasClass('date')) {
    if ($('#filter-list #filter-buttons').find('a[data-name="'+ $(this).closest('.checkbox').find('input').attr('value') + '"]').length < 1 && ($(this).closest('.checkbox').find('input').attr('data-name') != "undefined")) {
      if ($(this).closest('.checkbox').find('input').attr('data-name') != undefined) {
        $('#filter-list #filter-buttons').append('<a href="#" data-name="' + $(this).closest('.checkbox').find('input').attr('value')+ '">'+ $(this).closest('.checkbox').find('input').attr('data-name')+ ' <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10 fa-2x"><path fill="currentColor" d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z" class=""></path></svg></a>')
      }
    } else {
      $('#filter-list #filter-buttons').find('a[data-name="' + $(this).closest('.checkbox').find('input').attr('value') + '"]').remove();
    }
  }
	if (!$('#clear-all-2').hasClass('active')) {
    $('#clear-all-2').addClass('active')
    $('#featured-response').addClass('d-none')
  } else {
    $('#featured-response').removeClass('d-none')
  }
  getPosts('mobile')
  // return false;
});


$('#work-filter input:not(.all)').on('change',function(){
  if ($(this).closest('.filters').length > 0) {
    if ($(this).is(':checked')) {
      $('#filter-list #filter-buttons').append('<a href="#" data-name="'+ $(this).attr('value')+ '">'+ $(this).attr('data-name')+ ' <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10 fa-2x"><path fill="currentColor" d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z" class=""></path></svg></a>')
    } else {
      $('#filter-list #filter-buttons').find('a[data-name="' + $(this).attr('value') + '"]').remove();
    }
  }
  getPosts()
  // return false;
});

$('#work-filter input.all').on('change',function(){
  if ($(this).is(':checked')) {
    $('#work-filter .dropdown.active input:checkbox:not(:checked)').click()
  } else {
    $('#work-filter .dropdown.active input:checkbox:checked').click()
  }
});

if (window.location.href.split('?')[1] != undefined) {
  var preFiltered = $.map(window.location.href.split('?')[1].split('&'), function(e,i) { 
    return e.split('=')[1];
  });
}

if (preFiltered) {
  preFiltered.forEach(function(x,y) {
    $('#work-filter input[value="'+ x +'"]').closest('label').find('span').click()
  })
}

function getPosts(x="desktop"){
  var filter = $('#work-filter');
  if (x != 'desktop') {
    filter = $('#work-filter2');
  }
    if ($('#filter-list #filter-buttons a').length == 0) {
      $('#filter-list').removeClass('active')
      $('#featured-response').removeClass('d-none')
    } else {
      $('#filter-list').addClass('active')
      $('#featured-response').addClass('d-none')
    }

  $.ajax({
    url:filter.attr('action'),
    data:filter.serialize(), // form data
    type:filter.attr('method'), // POST
    success:function(data){
      $('#response').html(data); // insert data
    },
    error:function(data){
      $('#response').html(data); // insert data
    }
  });
}
