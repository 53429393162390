import AOS from 'aos';
import $ from "jquery";


window.addEventListener('load', () => {
  if ($(window).width() > 998) {
    AOS.init({
      // Global settings:
      throttleDelay: 49, // the delay on throttle used while scrolling the page (advanced)
    
    
      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 60, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 500, // values from 0 to 3000, with step 50ms
      easing: 'ease', // default easing for AOS animations
      once: true, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
    
    });
  }
})

// window.onUsersnapCXLoad = function(api) {
//   api.init();
// }
// var script = document.createElement('script');
// script.defer = 1;
// script.src = 'https://widget.usersnap.com/global/load/4301456a-40b0-4e53-9edd-e491d7d737f8?onload=onUsersnapCXLoad';
// document.getElementsByTagName('head')[0].appendChild(script);