import $ from "jquery"

let mypos = $(window).scrollTop()

$('#hamburger, #hamburger2').on('click',function() {
	$('#mobile-menu, .navbar:not(.fixed), #hamburger, #mobile-content, body').toggleClass('active')
	$('.search-container').removeClass('active')
})

$(window).scroll(function() {
	if (mypos > $('.navbar:not(.fixed)').outerHeight()) {
		$('.navbar.fixed').addClass('show')
	} else {
		$('.navbar.fixed').removeClass('show')
	}
	if (mypos < 120) {
		$('.navbar.fixed').removeClass('show')
	}
	mypos = $(window).scrollTop()
})

$('.desktop-nav .menu-item a').each(function() {
	$(this).attr('data-name',$(this).html())
})

$('.desktop-nav .menu-item a').on('click',function() {
	window.location = $(this).attr('href')
})

$('.search-button').on('click',function(e) {
	e.preventDefault()
	$('.search-container').toggleClass('active')
	$('.search-container input').focus()
})