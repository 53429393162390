import $ from "jquery";

$('.our-team .member').on('click',function(e) {
  e.preventDefault()
  $('.team-member-container').addClass('deactive')
  $('.member-details, .team-member-container a').removeClass('active')
  $('.member-details[data-id="'+ $(this).attr('data-id') +'"]').addClass('active')
  $('.team-member-container a[data-id="'+ $(this).attr('data-id') +'"]').addClass('active')
  if ($(window).width() < 998) {
    $('html, body').animate({
      scrollTop: jQuery(this).closest('.col-lg-4').find('.member-details.active').offset().top - 50
    });
  } else {
    $('html, body').animate({
      scrollTop: jQuery(".member-details.d-none.active").offset().top - 50
    });
  }
})

$('.collapse').on('click',function(e) {
  e.preventDefault()
  $('.team-member-container').removeClass('deactive')
  $('.member-details, .team-member-container a').removeClass('active')
})