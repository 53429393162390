import $ from "jquery";
import Slick from 'slick-carousel';

var $slidesToShow

if ($(window).width() > 767) {
  $slidesToShow = 2
} else {
  $slidesToShow = 1
}

$('.post-type-loader').each(function() {
  $(this).find('.post-slider').slick({
    slidesToShow: $slidesToShow,
    slidesToScroll: 1,
    arrows: true,
    infinite: false,
    pauseOnFocus: false,
    pauseOnHover: false,
    nextArrow: $(this).find('.slider-nav__next-arrow'),
    prevArrow: $(this).find('.slider-nav__prev-arrow'),
  });
})